const permissions = {
    search_placeholder: 'Buscar por API o nombre de permiso',
    search_placeholder_without_api: 'Buscar por nombre de permiso',
    name_column: 'Permiso',
    description_column: 'Descripción',
    api_column: 'API',
    placeholder_title: 'Permiso',
    placeholder_description: 'Permiso se refiere a la autorización para acceder a un recurso (lo llamamos recurso de API).',
    edit: 'Permiso de edición',
    delete: 'Permiso de eliminación',
    remove: 'Permiso de remover',
    edit_title: 'Editar permiso de API',
};
export default Object.freeze(permissions);

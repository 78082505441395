const logs = {
    page_title: 'Registros de auditoría',
    title: 'Registros de auditoría',
    subtitle: 'Ver datos de registro de eventos de autenticación realizados por sus usuarios.',
    event: 'Evento',
    user: 'Usuario',
    application: 'Aplicación',
    time: 'Tiempo',
    filter_by: 'Filtrar por',
};
export default Object.freeze(logs);

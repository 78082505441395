const demo_app = {
    title: '¡Ha iniciado sesión correctamente en la vista previa en vivo!',
    subtitle: 'Aquí está su información de usuario:',
    username: 'Nombre de usuario: ',
    user_id: 'ID de usuario: ',
    sign_out: 'Cerrar sesión en la vista previa en vivo',
    continue_explore: 'O continúe explorando',
    customize_sign_in_experience: 'Personalice la experiencia de inicio de sesión',
    enable_passwordless: 'Habilitar sin contraseña',
    add_social_connector: 'Agregar conector social',
};
export default Object.freeze(demo_app);

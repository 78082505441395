const invitation = {
    find_your_tenants: 'Encuentra a tus inquilinos',
    find_tenants_description: 'Tu dirección de correo electrónico puede estar registrada con varios inquilinos. Puedes elegir unirte a los existentes o continuar creando uno nuevo.',
    create_new_tenant: 'Crear un nuevo inquilino',
    email_not_match_title: 'Actualmente has iniciado sesión como\n{{email}}',
    email_not_match_description: 'Por favor, inicia sesión con la cuenta correcta para aceptar la invitación y convertirte en miembro de la organización.',
    switch_account: 'Inicia sesión con otra cuenta',
    invalid_invitation_status: 'Invitación inválida. Por favor contacta al administrador e intenta nuevamente.',
    invitation_not_found: 'Invitación no encontrada. Por favor, contacta al administrador.',
};
export default Object.freeze(invitation);
